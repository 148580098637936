import React, { useContext, useEffect, useMemo, useState } from "react";
// eslint-disable-next-line no-unused-vars
import Styled from "styled-components/macro";
import logo from "assets/images/logo-p2.png";
import { useMediaPredicate } from "react-media-hook";
import { SideNavContext } from "context/sideNavContext";
import { LoadingContext } from "context/loadingContext";
import { AuthContext } from "context/authContext";
import sideNavData from "nav.json";
import UserActions from "components/organisms/UserActions";
import {
  SideNavbar,
  Nav,
  Ul,
  CloseButton,
  LogoHolder,
  Logo,
} from "./SideNav.styles";
import Heading from "components/atoms/Heading";
import SubMenu from "./SubMenu";

function Navbar() {
  const { toggleSideNav, sideNavState } = useContext(SideNavContext);
  const [searchText, setSearchText] = useState("");
  const { allowedPages } = useContext(AuthContext);
  const { isLoading } = useContext(LoadingContext);
  const MaxWidth991 = useMediaPredicate("(max-width: 991px)");
  useEffect(
    () => !sideNavState && document.body.classList.remove("nav-active"),
    [sideNavState]
  );
  const sideBarItems = useMemo(
    () =>
      sideNavData
        .filter(({ file, live }) =>
          !searchText.trim()
            ? allowedPages.includes(file) &&
              (process?.env?.REACT_APP_MAIN_URL ===
              "https://portal.licence2drive.ca"
                ? live
                : true)
            : allowedPages.includes(file) &&
              file.includes(searchText.trim()) &&
              (process?.env?.REACT_APP_MAIN_URL ===
              "https://portal.licence2drive.ca"
                ? live
                : true)
        )
        .map((item, index) => <SubMenu item={item} key={index} />),
    [searchText, isLoading, sideNavData, allowedPages]
  );
  return (
    <>
      <SideNavbar
        css={isLoading && "background:var(--dark);"}
        $loading={isLoading}
      >
        {MaxWidth991 && (
          <CloseButton onClick={toggleSideNav}>
            <i className="material-icons-outlined">close</i>
          </CloseButton>
        )}
        {/* <SearchField>
          <StyledField
            type="search"
            placeholder="Search..."
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            prefix={<i className="material-icons-outlined">search</i>}
          />
        </SearchField> */}
        <LogoHolder>
          <Logo to="/">
            {/* <img src={logo} alt="plastk" className="logo-small" /> */}
            {/* <img src={logo} alt="plastk" className="logo-full" /> */}
            <Heading className="logo-full" level={3}>
              Toronto Drivers
            </Heading>
            <Heading className="logo-small" level={3}>
              TDA
            </Heading>
          </Logo>
        </LogoHolder>

        <Nav id="sub-menu">
          <Ul>{sideBarItems}</Ul>
        </Nav>
        <UserActions
          toggleSideNav={toggleSideNav}
          profile={sideBarItems.filter(
            (item) => item.props.item.name === "Profile"
          )}
        />
      </SideNavbar>
    </>
  );
}
export default Navbar;
